import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import * as React from "react";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Digital TORC Manager © '}
            {new Date().getFullYear()}{' '}
            <Link color="inherit" href="https://torc.no/">
                torc.no
            </Link>
        </Typography>
    );
}

export default Copyright;