export function ConvertScenarioFromAPI (scenario : any) : any
{
    let newTags : any[] = []
    let newSceneDescription : any[] = []

    if(scenario["tags"] != null)
    {
        for(let k=0 ; k < scenario["tags"].length;k++)
        {
            newTags.push({tags : scenario["tags"][k]})
        }
    }

    if(scenario["scene_description"] != null)
    {
        for(let k=0 ; k < scenario["scene_description"].length;k++)
        {
            newSceneDescription.push({scene_description : scenario["scene_description"][k]})
        }
    }
    scenario["tags"] = newTags
    scenario["scene_description"] = newSceneDescription

    return scenario
}

export function ConvertScenarioToAPI(scenario : any, filter: boolean) : any {
    if(scenario["tags"] != null)
    {
        let newTags : any[] = []
        for(let k=0 ; k < scenario["tags"].length;k++)
        {
            newTags.push(scenario["tags"][k]["tags"])
        }
        scenario["tags"] = newTags
    }

    if(scenario["scene_description"] != null && !filter)
    {
        let newSceneDescription : any[] = []
        for(let l=0 ; l < scenario["scene_description"].length;l++)
        {
            newSceneDescription.push(scenario["scene_description"][l]["scene_description"])
        }
        scenario["scene_description"] = newSceneDescription
    }

    return scenario
}