import React from "react";
import {
    FormDataConsumer,
    SimpleForm,
    TextField,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    DateTimeInput,
    NumberInput,
    Edit, TextInput, required
} from "react-admin";
import Box from "@mui/material/Box";

const EditTitle = () => {
    return <span> Edit Menu of : <TextField source="match_id"/> </span>;
};


export const GamesEdit = (props: any) => {

    return (
        <Edit title={<EditTitle/>} {...props}>
            <SimpleForm>
                <Box width="40%" justifyContent="flex-end" >
                    <TextInput source="name" validate={required()} resettable/>
                </Box >
                <ReferenceInput source="scenario_id" reference="scenarios" allowEmpty>
                    <SelectInput optionText="name" emptyText={"No scenario"}/>
                </ReferenceInput>
                <ReferenceInput source="deck_id" reference="decks" allowEmpty>
                    <SelectInput optionText="name" emptyText={"No deck"}/>
                </ReferenceInput>
                <DateTimeInput label="Deleted After" source="options.deleted_after"/>
                <SelectInput label="Game mode" source="options.mode" defaultValue={"RESILIENCE_MODE"} isRequired choices={[
                    { id: "RESILIENCE_MODE", name: 'Resilience' },
                    { id: "RISK_MODE", name: 'Risk' },
                ]} />
                <SelectInput label="Game Server" source="options.game_server" defaultValue={"EUROPE"} isRequired choices={[
                    { id: "EUROPE", name: 'Europe (France)' },
                    { id: "NORWAY", name: 'Norway' },
                ]} />
                <BooleanInput label="Enable Feasibility" source="options.feasibility_enabled" />
                <FormDataConsumer>
                    {({ formData, ...rest }) => formData.options.feasibility_enabled &&
                        <SelectInput label="Feasibility Level" source="options.feasibility_level" isRequired={formData.options.feasibility_enabled} choices={[
                            { id: 0, name: 'Organizational Level' },
                            { id: 1, name: 'Domain Level' },
                            { id: 2, name: 'Abstract Level' },
                        ]} {...rest} />
                    }
                </FormDataConsumer>
                <NumberInput label="Nb selectable cards (normal)" source="options.normal_selection_options.nb_selectable_cards" />
                <BooleanInput label="All players can select (normal)" source="options.normal_selection_options.all_players_can_select" />
                <NumberInput label="Nb selectable cards (anticipation)" source="options.anticipation_selection_options.nb_selectable_cards" />
                <BooleanInput label="All players can select (anticipation)" source="options.anticipation_selection_options.all_players_can_select" />
                <NumberInput label="Nb decisions" source="options.decision_options.nb_decisions" />
                <NumberInput label="Nb cards per decision" source="options.decision_options.nb_cards_per_decision" />
                <BooleanInput label={"Reset roles on new round"} source={"options.reset_roles_on_new_round"}/>
                <BooleanInput label={"Show Stage Instructions"} source={"options.show_stage_instruction"}/>
            </SimpleForm>
        </Edit>
    )
}
