import React from "react";
import {Filter, SearchInput} from "react-admin";


export const ScenarioFilter = (props:any) => (
    <Filter {...props}>
        <SearchInput source="name" alwaysOn />
        {/*<ArrayInput source="tags" label="Filter Tags">*/}
        {/*    <SimpleFormIterator>*/}
        {/*        <TextInput source="tags" label="Tags"/>*/}
        {/*    </SimpleFormIterator>*/}
        {/*</ArrayInput> */}
    </Filter>
);