import React from "react";
import {
    ArrayInput,
    Create,
    FormDataConsumer,
    required,
    SimpleForm,
    SimpleFormIterator,
    TextField,
    TextInput,
    SelectArrayInput,
    BooleanInput,
} from "react-admin";
import Box from '@mui/material/Box';

// @ts-ignore
const ActionCardsMetadataField = () => {
    return <div style={{width: '100%'}}>
        <Box width="25%" justifyContent="flex-end">
            <TextInput source="name" validate={required()} resettable/>
        </Box>
        <Box width="25%">
            <ArrayInput source="tags">
                <SimpleFormIterator>
                    <TextInput source="tags" label="Tags"/>
                    <FormDataConsumer>
                        {({getSource, scopedFormData}) => {
                            return (
                                <TextField
                                    record={scopedFormData}
                                    source={getSource === undefined ? "" : getSource('tags')}
                                />
                            );
                        }}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
        </Box>
    </div>;
}

export const ActionCardsCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <ActionCardsMetadataField {...props} />
                <TextInput multiline fullWidth source="description"/>
                <SelectArrayInput label="Stages" source="stages" choices={[
                    {id: 1, name: 'Situational Awareness'},
                    {id: 2, name: 'Sense Making'},
                    {id: 3, name: 'Anticipation'},
                    {id: 5, name: 'Monitor Effects'}
                ]} />
                <BooleanInput label="Procedure" source="is_procedure" />
            </SimpleForm>
        </Create>
    )
}