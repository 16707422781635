export function ConvertActionCardsFromAPI (actionCards : any) : any
{
    if(actionCards["tags"] != null)
    {
        let newTags : any[] = []
        for(let k=0 ; k < actionCards["tags"].length;k++)
        {
            newTags.push({tags : actionCards["tags"][k]})
        }
        actionCards["tags"] = newTags
    }

    return actionCards
}

export function ConvertActionCardsToAPI (actionCards : any, filter : boolean) : any
{
    if(actionCards["tags"] != null)
    {
        let newTags : any[] = []
        for(let k=0 ; k < actionCards["tags"].length;k++)
        {
            newTags.push(actionCards["tags"][k]["tags"])
        }
        actionCards["tags"] = newTags
    }

    return actionCards
}