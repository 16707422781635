import React from "react";
import {
    ArrayInput,
    Edit, ReferenceArrayInput,
    required, SelectArrayInput,
    SimpleForm,
    SimpleFormIterator,
    TextField,
    TextInput
} from "react-admin";

const EditTitle = () => {
    return <span> Edit Menu of : <TextField source="name"/> </span>;
};

export const DeckEdit = (props: any) => (
    <Edit title={<EditTitle/>} {...props}>
        <SimpleForm>
            <TextInput source="name" validate={required()} resettable/>
            <ArrayInput source="tags">
                <SimpleFormIterator>
                    <TextInput source="tags" label="Tags"/>
                </SimpleFormIterator>
            </ArrayInput>
            <TextInput multiline fullWidth source="description" label="Description"/>
            <ReferenceArrayInput source="cards" reference="action-cards">
                <SelectArrayInput optionText="name"/>
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
);
