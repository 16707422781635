import * as React from "react";
import {Card, CardContent, CardHeader} from '@mui/material';
import {Title, useGetList} from 'react-admin';
import Grid from '@mui/material/Grid';
import {makeStyles} from '@mui/styles';
import {Theme} from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flexGrow: 1,
        margin: theme.spacing(2)
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const GamesCard = () => {
    const {data, isLoading, error} = useGetList('games')
    const nbGames = data ? data.length : 0
    return <Card>
        <CardHeader title="Games"/>
        {
            isLoading ? <CardContent><span>Loading</span></CardContent> :
                error ? <CardContent><span>Error</span></CardContent> :
                    <CardContent>{nbGames} {nbGames <= 1 ? "game" : "games"}</CardContent>
        }
    </Card>
}

const ScenariosCard = () => {
    const {data, isLoading, error} = useGetList('scenarios')
    const nbScenarios = data ? data.length : 0
    return <Card>
        <CardHeader title="Scenarios"/>
        {
            isLoading ? <CardContent><span>Loading</span></CardContent> :
                error ? <CardContent><span>Error</span></CardContent> :
                    <CardContent>{nbScenarios} {nbScenarios <= 1 ? "scenario" : "scenarios"}</CardContent>
        }
    </Card>
}

const ActionCardsCard = () => {
    const {data, isLoading, error} = useGetList('action-cards')
    const nbActionsCards = data ? data.length : 0
    return <Card>
        <CardHeader title="Action cards"/>
        {
            isLoading ? <CardContent><span>Loading</span></CardContent> :
                error ? <CardContent><span>Error</span></CardContent> :
                    <CardContent>{nbActionsCards} {nbActionsCards <= 1 ? "action card" : "action cards"}</CardContent>
        }
    </Card>
}


const DecksCard = () => {
    const {data, isLoading, error} = useGetList('decks')
    const nbDecks = data ? data.length : 0
    return <Card>
        <CardHeader title="Deck"/>
        {
            isLoading ? <CardContent><span>Loading</span></CardContent> :
                error ? <CardContent><span>Error</span></CardContent> :
                    <CardContent>{nbDecks} {nbDecks <= 1 ? "deck" : "decks"}</CardContent>
        }
    </Card>
}


function Dashboard() {
    const classes = useStyles();
    return (
        <div>
            <Title title="My TORC"/>
            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card>
                            <CardHeader title="Welcome to My TORC"/>
                            <CardContent>
                                My TORC is the manager user interface of the Digital TORC game. The manager can be used
                                to create pre-loaded cards and scenarios which can be used
                                in multiple game sessions. The pre-loaded cards can be put together in a deck, which
                                then can be used in a game session. The manager allow you
                                to easily plan and prepare your game session. When a game session has ended, you can
                                easily fetch the cards created during the game, which then are ready to be used
                                in another game session. You can read more in the <a href={"https://documentation.torc.no/manager/"}>documentation</a>.</CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <GamesCard/>
                    </Grid>
                    <Grid item xs={6}>
                        <ScenariosCard/>
                    </Grid>
                    <Grid item xs={6}>
                        <ActionCardsCard/>
                    </Grid>
                    <Grid item xs={6}>
                        <DecksCard/>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

export default Dashboard;

