import React from "react";
import {
    ArrayInput, BooleanInput,
    Edit,
    required, SelectArrayInput,
    SimpleForm,
    SimpleFormIterator,
    TextField,
    TextInput
} from "react-admin";

const EditTitle = () => {
    return <span> Edit Menu of : <TextField source="name"/> </span>;
};

export const ActionCardsEdit = (props: any) => (
    <Edit title={<EditTitle/>} {...props}>
        <SimpleForm>
            <TextInput source="name" validate={required()} resettable/>
            <ArrayInput source="tags">
                <SimpleFormIterator>
                    <TextInput source="tags" label="Tags"/>
                </SimpleFormIterator>
            </ArrayInput>
            <TextInput multiline fullWidth source="description" label="Description"/>
            <SelectArrayInput label="Stages" source="stages" choices={[
                {id: 1, name: 'Situational Awareness'},
                {id: 2, name: 'Sense Making'},
                {id: 3, name: 'Anticipation'},
                {id: 5, name: 'Monitor Effects'}
            ]} />
            <BooleanInput label="Procedure" source="is_procedure" />
        </SimpleForm>
    </Edit>
);
