import {ConvertScenarioFromAPI, ConvertScenarioToAPI} from "./adapter_scenarios";
import {ConvertActionCardsFromAPI, ConvertActionCardsToAPI} from "./adapter_actionCards";
import {ConvertDeckFromAPI, ConvertDeckToAPI} from "./adapter_deck";
import {ConvertGameFromAPI, ConvertGameToAPI} from "./adapter_games";

export function ConvertResourceToAPI(resourceName: string, resource : any, filter=false) : any {
    if (resourceName === "scenarios") {
        return ConvertScenarioToAPI(resource, filter)
    }
    else if (resourceName === "action-cards") {
        return ConvertActionCardsToAPI(resource, filter)
    }
    else if (resourceName === "decks") {
        return ConvertDeckToAPI(resource, filter)
    } else if (resourceName === "games") {
        return ConvertGameToAPI(resource)
    }
    console.error("unknown resourceName : ",resourceName)
}

export function ConvertResourceFromAPI(resourceName: string, resource : any) : any {
    if (resourceName === "scenarios") {
        return ConvertScenarioFromAPI(resource)
    }
    else if (resourceName === "action-cards") {
        return ConvertActionCardsFromAPI(resource)
    }
    else if (resourceName === "decks") {
        return ConvertDeckFromAPI(resource)
    } else if (resourceName === "games") {
        return ConvertGameFromAPI(resource)
    }
    console.error("unknown resourceName : ",resourceName)
}

export function FromReactToAPI (resourceName: string, resources : any[], filter=false) : any[]
{
    let newResources : any[] = []

    if (resources === null) {
        return newResources
    }

    for(let k=0; k< resources.length ;k++)
    {
        newResources.push(ConvertResourceToAPI(resourceName, resources[k], filter))
    }

    return newResources
}

export function FromAPIToReact (resourceName: string, resources : any[], start = 0, end = resources.length-1) : any[]
{
    let newResources : any[] = []

    if (resources === null) {
        return newResources
    }

    for(let k=0; k< resources.length ;k++)
    {
        newResources.push(ConvertResourceFromAPI(resourceName, resources[k]))
    }

    return newResources
}