import { defaultTheme } from 'react-admin';

const sintefBlue = "#003c65"
//const sintefAccent = "#14b978"
const sintefRed = "#be3c37"
const sintefYellow = "#c89B14"
const sintefGreen = "#004628"

const TorcTheme = {
    ...defaultTheme,
    palette: {
         ...defaultTheme.palette,
        type: 'light',
        primary: {
            main: sintefBlue,
            contrastText: '#fff',
        },
        secondary: {
            main: sintefBlue,
            contrastText: '#fff',
        },
        error: {
            main: sintefRed,
        },
        warning: {
            main: sintefYellow,
        },
        success: {
            main: sintefGreen,
        },
    },
}

export default TorcTheme;