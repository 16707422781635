import React from "react";
import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    DateField,
    ArrayField,
    ChipField,
    SingleFieldList, ReferenceArrayField, useRecordContext,
} from "react-admin";

const ShowTitle = () => {
    const record = useRecordContext();
    return  <div>{record.name}</div>;
};

export const DeckShow = (props: any) => (
    <Show title={<ShowTitle/>} {...props}>
        <TabbedShowLayout>
            <Tab label="Information">
                <TextField source="name" label="Name"/>
                <DateField source="created_on" label="Created on"/>
                <DateField source="last_updated" label="Last Updated"/>
                <ArrayField source="tags">
                    <SingleFieldList>
                        <ChipField clickable={false} color="primary" source="tags" label="Tags" />
                    </SingleFieldList>
                </ArrayField>
                <ReferenceArrayField source="cards" reference="action-cards">
                    <SingleFieldList linkType="show">
                        <ChipField color="secondary" source="name" label="Cards" clickable />
                    </SingleFieldList>
                </ReferenceArrayField>
                <TextField source="description"/>
            </Tab>
        </TabbedShowLayout>
    </Show>
);