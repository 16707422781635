import * as React from 'react';
import {UserMenu, Logout} from "react-admin";
import {ListItemIcon, ListItemText, MenuItem} from "@mui/material";
import Link from "@mui/material/Link";
import SettingsIcon from '@mui/icons-material/Settings';
import {AUTH_SERVICE} from "../../config";

const AccountUrl = AUTH_SERVICE + "account";

const MyAccountMenu = React.forwardRef((props, ref) => {
    return (
        <MenuItem
            ref={ref}
            component={Link}
            {...props}
            href={AccountUrl}
        >
            <ListItemIcon>
                <SettingsIcon />
            </ListItemIcon>
            <ListItemText>
                My Account
            </ListItemText>
        </MenuItem>
    );
});

const MyTorcUserMenu = (props) => (
    <UserMenu {...props}>
        <MyAccountMenu/>
        <Logout/>
    </UserMenu>
);

export default MyTorcUserMenu;