import React from "react";
import { ArrayInput, Create, FormDataConsumer, required, SimpleForm, SimpleFormIterator, TextField, TextInput, ReferenceArrayInput, SelectArrayInput } from "react-admin";
import Box from '@mui/material/Box';

// @ts-ignore
const DeckMetadataField = () => (
    <div style={{ width: '100%'}}>
        <Box width="25%" justifyContent="flex-end" >
            <TextInput source="name" validate={required()} resettable/>
        </Box >
        <Box width="25%" >
            <ArrayInput source="tags">
                <SimpleFormIterator>
                    <TextInput source="tags" label="Tags"/>
                    <FormDataConsumer>
                        {({ getSource, scopedFormData }) => {
                            return (
                                <TextField
                                    record={scopedFormData}
                                    source ={getSource === undefined? "" : getSource('tags')}
                                />
                            );
                        }}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
        </Box>
    </div>
)

export const DeckCreate = (props: any) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <DeckMetadataField {...props} />
                <TextInput multiline fullWidth source="description"/>
                <ReferenceArrayInput source="cards" reference="action-cards">
                    <SelectArrayInput optionText="name"/>
                </ReferenceArrayInput>
            </SimpleForm>
        </Create>
    )
}