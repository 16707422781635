import React from "react";
import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    DateField,
    ArrayField,
    ChipField,
    SingleFieldList, BooleanField, useRecordContext,
} from "react-admin";
import Typography from "@mui/material/Typography";
import {v4 as uuidv4} from "uuid";


const ShowTitle = () => {
    const record = useRecordContext();
    return  <div>{record.name}</div>;
};

let stageNames : {[id: number]: string; } = {
    1: "Situational Awareness",
    2: "Sense Making",
    3: "Anticipation",
    5: "Monitor Effects",
}

function getStageName(stage: number): string {
    return(stageNames[stage])
}

// @ts-ignore
const StagesField = () => {
    const record = useRecordContext();
    return <div>
        <Typography>Stages</Typography>
        <ul>
            {record.stages.map((stage: number) => (
                <li key={uuidv4()}>{getStageName(stage)}</li>
            ))}
        </ul>
    </div>;
}


export const ActionCardsShow = (props: any) => (
    <Show title={<ShowTitle/>} {...props}>
        <TabbedShowLayout>
            <Tab label="Information">
                <TextField source="name" label="Name"/>
                <DateField source="created_on" label="Created on"/>
                <DateField source="last_updated" label="Last Updated"/>
                <ArrayField source="tags">
                    <SingleFieldList>
                        <ChipField clickable={false} color="primary" source="tags" label="Tags"/>
                    </SingleFieldList>
                </ArrayField>
                <StagesField {...props} />
                <TextField source="description"/>
                <BooleanField source="is_procedure" label="Procedure"/>
            </Tab>
        </TabbedShowLayout>
    </Show>
);