import React from 'react';
import './App.css';
// @ts-ignore
import {Admin, AppBar, Layout, Resource} from "react-admin";
import authProvider from "./auth/sintefAuthProvider";
import LoginPage from "./pages/LoginPage";
import TorcTheme from "./Theme";
import torcManagerRestProvider from './dataProvider/torcManagerRestProvider';
import {MANAGER_API_ADDRESS} from "./config";
import authenticatedHttpClient from './auth/authenticatedHttpClient';
import {ScenarioCreate} from './models/scenarios/ScenarioCreate';
import {ScenarioShow} from './models/scenarios/ScenarioShow';
import {ScenarioList} from './models/scenarios/ScenarioList';
import {ScenarioEdit} from './models/scenarios/ScenarioEdit';
import {ActionCardsCreate} from './models/actionCards/ActionCardsCreate';
import {ActionCardsShow} from './models/actionCards/ActionCardsShow';
import {ActionCardsList} from './models/actionCards/ActionCardsList';
import {ActionCardsEdit} from './models/actionCards/ActionCardsEdit';
import {DeckCreate} from './models/deck/DeckCreate';
import {DeckList} from './models/deck/DeckList';
import {DeckEdit} from './models/deck/DeckEdit';
import {DeckShow} from './models/deck/DeckShow';
import {GamesList} from "./models/games/gamesList";
import {GamesCreate} from "./models/games/gamesCreate";
import {GamesEdit} from "./models/games/gamesEdit";
import {GamesShow} from "./models/games/gamesShow";
import ReorderIcon from '@mui/icons-material/Reorder';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import DescriptionIcon from '@mui/icons-material/Description';
import BuildIcon from '@mui/icons-material/Build';
import Dashboard from "./pages/Dashboard";
import MyTorcUserMenu from "./components/common/UserMenu";

const dataProvider = torcManagerRestProvider(MANAGER_API_ADDRESS, authenticatedHttpClient);

const TorcAppBar = (props: any) => <AppBar {...props} userMenu={<MyTorcUserMenu/>}/>;
const TorcLayout = (props: any) => <Layout {...props} appBar={TorcAppBar} />;

const App = () =>
    <Admin title={"My TORC"} authProvider={authProvider} dataProvider={dataProvider} dashboard={Dashboard} loginPage={LoginPage} theme={TorcTheme} layout={TorcLayout} disableTelemetry requireAuth>
        <Resource name="games" list={GamesList} create={GamesCreate} edit={GamesEdit} show={GamesShow} icon={PlayCircleFilledIcon}/>
        <Resource name="scenarios" list={ScenarioList} create={ScenarioCreate} edit={ScenarioEdit} show={ScenarioShow} icon={DescriptionIcon}/>
        <Resource name="decks" list={DeckList} create={DeckCreate} edit={DeckEdit} show={DeckShow} icon={ReorderIcon}/>
        <Resource name="action-cards" options={{ label: 'Action cards' }} list={ActionCardsList} create={ActionCardsCreate} edit={ActionCardsEdit} show={ActionCardsShow} icon={BuildIcon}/>
    </Admin>;

export default App;

