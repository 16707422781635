import Typography from "@mui/material/Typography";
import * as React from "react";
import {MANAGER_VERSION} from "../../config";

function ManagerVersion() {
    return (
        <Typography variant="body2" color="textSecondary" align="right">
            {" version " + MANAGER_VERSION}
        </Typography>
    );
}

export default ManagerVersion;
