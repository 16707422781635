import React, {cloneElement, useState} from "react";
import {
    List,
    Datagrid,
    TextField,
    BooleanField,
    DateField,
    ArrayField,
    EditButton,
    ChipField,
    SingleFieldList,
    TopToolbar,
    CreateButton,
    Button,
    ExportButton, useRecordContext, downloadCSV, useRefresh
} from "react-admin";
import {v4 as uuidv4} from "uuid";
import IconUpload from '@mui/icons-material/Publish';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MaterialButton from '@mui/material/Button';
import {UploadFiles} from "../../components/upload-file-component";
import {ActionCardsFilter} from "./ActionCardsFilter";
import jsonExport from 'jsonexport/dist';

let stageNames: { [id: number]: string; } = {
    1: "Situational Awareness",
    2: "Sense Making",
    3: "Anticipation",
    5: "Monitor Effects",
}

function getStageName(stage: number): string {
    return (stageNames[stage])
}

// @ts-ignore
const StagesField = () => {
    const record = useRecordContext();
    return <div>
        <ul>
            {record.stages.map((stage: number) => (
                <li key={uuidv4()}>{getStageName(stage)}</li>
            ))}
        </ul>
    </div>
}

const actionCardsExporter = (cards: any) => {
    const cardsForExport = cards.map((card: any) => {
        const stages = card.stages.map((stageId: number) => stageNames[stageId]).join(', ')
        return {
            name: card.name,
            description: card.description,
            is_default: card.is_default,
            is_procedure: card.is_procedure,
            created_on: card.created_on,
            last_updated: card.last_updated,
            stages: stages
        }
    });
    jsonExport(cardsForExport, {
        headers: ['name', 'description', 'is_default', 'is_procedure', 'created_on', 'last_updated', 'stages']
    }, (err: Error, csv: any) => {
        downloadCSV(csv, 'action_cards');
    });
};

const ListActions = (props: any) => (
    <TopToolbar>
        {cloneElement(props.filters, {context: 'button'})}
        <CreateButton/>
        <ExportButton/>
        <Button
            onClick={props.onUploadClick}
            label="Upload"
        >
            <IconUpload/>
        </Button>
    </TopToolbar>
);

interface UploadDialogProps {
    open: boolean
    onClose: () => void
}

const UploadDialog = (props: UploadDialogProps) => {
    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Action cards - Upload</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    You can upload several action cards at once from a JSON file.
                </DialogContentText>
                <UploadFiles/>
            </DialogContent>
            <DialogActions>
                <MaterialButton onClick={props.onClose} color="primary">
                    Close
                </MaterialButton>
            </DialogActions>
        </Dialog>)
}

export const ActionCardsList = (props: any) => {
    const [openUploadDialog, setUploadDialog] = useState(false);
    const refresh = useRefresh();

    return (
        <div>
            <List filters={<ActionCardsFilter/>} {...props} title="List of action cards"
                  actions={<ListActions onUploadClick={() => setUploadDialog(true)}/>}
                  exporter={actionCardsExporter}>
                <Datagrid rowClick={"show"}>
                    <TextField source="name"/>
                    <DateField source="created_on" label="Created on"/>
                    <DateField source="last_updated" label="Last Updated"/>
                    <ArrayField source="tags">
                        <SingleFieldList>
                            <ChipField color="primary" source="tags" label="Tags" clickable={false}/>
                        </SingleFieldList>
                    </ArrayField>
                    <TextField source="description" sortable={false}/>
                    <StagesField {...props} label="Stages"/>
                    <BooleanField source="is_procedure" label="Procedure"/>
                    <EditButton/>
                </Datagrid>
            </List>
            <UploadDialog open={openUploadDialog} onClose={() => {
                setUploadDialog(false)
                refresh()
            }}/>
        </div>
    )
}
