import {fetchUtils} from "react-admin";
import {MANAGER_API_ADDRESS} from "../config";
import axios from "axios";

export const fetchJson = (url: string, options: fetchUtils.Options = {}) => {
    options.credentials = "include";
    return fetchUtils.fetchJson(url, options);
};

export const simpleGet = (url: string) => {
    return fetch(url, {
        credentials: "include",
    })
};

export const authenticatedUpload = (resource: string, file: any, onUploadProgress: any) => {
    const authenticatedAxios = axios.create(
        {
            baseURL: MANAGER_API_ADDRESS,
            withCredentials: true,
        }
    )

    let formData = new FormData()
    formData.append("file", file);

    return authenticatedAxios.post(MANAGER_API_ADDRESS + "/" + resource + "/file-upload", formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
    })
};

export default fetchJson;