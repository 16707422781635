export function ConvertGameFromAPI (game : any) : any
{
    if (game.scenario_id === null) {
        game.scenario_id = ""
    }
    if (game.deck_id === null) {
        game.deck_id = ""
    }
    return game
}

export function ConvertGameToAPI(game : any) : any {
    if (game.scenario_id === "") {
        game.scenario_id = null
    }
    if (game.deck_id === "") {
        game.deck_id = null
    }
    return game
}