import React from "react";
import { ArrayInput, Create, FormDataConsumer, required, SimpleForm, SimpleFormIterator, TextField, TextInput } from "react-admin";
import Box from '@mui/material/Box';

// @ts-ignore
const ScenarioMetadataField = () => (
    <div style={{ width: '100%'}}>
        <Box width="25%" justifyContent="flex-end" >
            <TextInput source="name" validate={required()} resettable/>
        </Box >
        <Box width="25%" >
            <ArrayInput source="tags">
                <SimpleFormIterator>
                    <TextInput source="tags" label="Tags"/>
                    <FormDataConsumer>
                        {({ getSource, scopedFormData }) => {
                            return (
                                <TextField
                                    record={scopedFormData}
                                    source ={getSource === undefined? "" : getSource('tags')}
                                />
                            );
                        }}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
        </Box>
    </div>
)

export const ScenarioCreate = (props: any) => (
    <Create {...props}>
        <SimpleForm>
            <ScenarioMetadataField {...props} />
            <TextInput multiline fullWidth source="description" />
            <ArrayInput source="scene_description" label="Contents">
                <SimpleFormIterator>
                    <TextInput source="scene_description" label="Content" />
                        <FormDataConsumer>
                            {({ getSource, scopedFormData }) => {
                                return (
                                    <TextField
                                        record={scopedFormData}
                                        source ={getSource === undefined? "" : getSource('scene_description')}
                                    />
                                );
                            }}
                        </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput> 
            <ArrayInput source="stressors">
                <SimpleFormIterator>
                    <TextInput source="name" label="Name" />
                        <FormDataConsumer>
                            {({ getSource, scopedFormData }) => {
                                return (
                                    <TextField
                                        record={scopedFormData}
                                        source ={getSource === undefined? "" : getSource('name')}
                                    />
                                );
                            }}
                        </FormDataConsumer>
                    <TextInput source="description" label="Description" />
                </SimpleFormIterator>
            </ArrayInput>          
        </SimpleForm>  
    </Create>
    );