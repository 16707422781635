import React from "react";
import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    DateField,
    ReferenceField, useRecordContext,
} from "react-admin";
import Typography from "@mui/material/Typography";

const ShowTitle = () => {
    const record = useRecordContext();
    return  <div>{"Showing Game " + record.match_id}</div>;
};

const ShowFeasibilityLevel = ({className}: any) => {
    const record = useRecordContext();
    return <Typography component="span"
                       variant="body2"
                       className={className}>
        {record.options.feasibility_enabled === false? "Feasibility Disabled" : record.options.feasibility_level === 0 ? "Organizational Level" : record.options.feasibility_level === 1 ? "Domain Level" : record.options.feasibility_level === 2 ?"Abstract Level" : "Feasibility Disabled" }
    </Typography>;
}


export const GamesShow = (props: any) => (
    <Show title={<ShowTitle/>} {...props}>
        <TabbedShowLayout>
            <Tab label="Information">
                <TextField label="Name" source="name"/>
                <TextField label="Game ID" source="match_id"/>
                <TextField label="Mode" source="options.mode"/>
                <ShowFeasibilityLevel label="Feasibility Level"/>
                <DateField label="Deleted after" source="options.deleted_after"/>
                <ReferenceField label="Scenario" source="scenario_id" reference="scenarios" link="show" emptyText={"No scenario"}>
                    <TextField source="name"/>
                </ReferenceField>
                <ReferenceField label="Deck" source="deck_id" reference="decks" link="show" emptyText={"No deck"}>
                    <TextField source="name"/>
                </ReferenceField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);