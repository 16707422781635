export function ConvertDeckFromAPI (deck : any) : any
{
    if(deck["tags"] != null)
    {
        let newTags : any[] = []
        for(let k=0 ; k < deck["tags"].length;k++)
        {
            newTags.push({tags : deck["tags"][k]})
        }
        deck["tags"] = newTags
    }

    return deck
}

export function ConvertDeckToAPI (deck : any, filter : boolean) : any
{
    if(deck["tags"] != null)
    {
        let newTags : any[] = []
        for(let k=0 ; k < deck["tags"].length;k++)
        {
            newTags.push(deck["tags"][k]["tags"])
        }
        deck["tags"] = newTags
    }

    return deck
}