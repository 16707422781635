import React from "react";
import {
    Show,
    TabbedShowLayout,
    Tab,
    TextField,
    DateField,
    ArrayField,
    ChipField,
    SingleFieldList, useRecordContext,
} from "react-admin";
import {v4 as uuidv4} from 'uuid';
import Typography from '@mui/material/Typography';

const ShowTitle = () => {
    const record = useRecordContext();
    return  <div>{record.name}</div>;
};

// @ts-ignore
const DescriptionsField = () => {
    const record = useRecordContext();
    return <div>
        <Typography variant={"h6"}>Scene description</Typography>
        {record.scene_description.map((item: any) => (
            <p key={uuidv4()}>{item.scene_description}</p>
        ))}
    </div>
}

// @ts-ignore
const StressorsField = () => {
    const record = useRecordContext();
    return <div>
        <Typography variant={"h6"}>Stressors</Typography>
        <ul>
            {record.stressors ? record.stressors.map((stressor: any) => (
                <li key={uuidv4()}><span>{stressor.name}: </span> {stressor.description}</li>
            )) : <p>No Stressors</p>}
        </ul>
    </div>
}

export const ScenarioShow = (props: any) => (
    <Show title={<ShowTitle/>} {...props}>
        <TabbedShowLayout>
            <Tab label="Information">
                <TextField source="name" label="Name"/>
                <DateField source="created_on" label="Created on"/>
                <DateField source="last_updated" label="Last Updated"/>
                <ArrayField source="tags">
                    <SingleFieldList>
                        <ChipField clickable={false} color="primary" source="tags" label="Tags"/>
                    </SingleFieldList>
                </ArrayField>
                <TextField source="description"/>
            </Tab>
            <Tab label="Scenario" path="scenario">
                <DescriptionsField {...props} />
                <StressorsField {...props} />
            </Tab>
        </TabbedShowLayout>
    </Show>
);