import React from "react";
import { List, Datagrid, TextField, DateField, ArrayField, EditButton, ChipField, SingleFieldList} from "react-admin";
import { ScenarioFilter } from "./ScenarioFilter";

export const ScenarioList = (props: any) => (
    <List filters={<ScenarioFilter />} {...props} title="List of scenarios">
            <Datagrid rowClick={"show"}>
                <TextField source="name"/> 
                <DateField source="created_on" label="Created on" />
                <DateField source="last_updated" label="Last Updated" />
                <ArrayField source="tags">
                    <SingleFieldList>
                        <ChipField color="primary" source="tags" label="Tags" clickable={false}/>
                    </SingleFieldList>
                </ArrayField>    
                <TextField source="description" sortable={false}/>
                <EditButton />
            </Datagrid>
    </List>
);
