import React from 'react';
import {Theme} from "@mui/material/styles";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import ManagerLogo from "../assets/manager_logo.svg";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import TorcManagerFooter from "../components/common/Footer";
import {makeStyles} from '@mui/styles';
import {MANAGER_AUTH_ADDRESS} from "../config";

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {
    }
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(10),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(2),
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(5, 0, 2),
        marginBottom: "10px"
    },
    error: {
        color: theme.palette.error.main,
    }
}));


//@ts-ignore
export default function LoginPage() {
    const classes = useStyles()

    const goToLogin = () => {
        window.location.replace(MANAGER_AUTH_ADDRESS + '/auth/login')
    }

    return (
        <div>
            <Container component="main" maxWidth="xs">
                {/*<CssBaseline/>*/}
                <div className={classes.paper}>
                    <Avatar className={classes.avatar} src={ManagerLogo}>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        My TORC
                    </Typography>
                    <div className={classes.form}>
                        <Typography component={"div"} variant={"body1"}>
                            Accessing My TORC requires an account delivered by SINTEF.
                        </Typography>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={goToLogin}
                            style={{marginBottom: "10px", marginTop: "10px"}}
                        >
                            Login with SINTEF
                        </Button>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                Don't have an account? <Link href="https://torc.no/contact.html" variant="body2">
                                Contact us!
                            </Link>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </Container>
            <TorcManagerFooter/>
        </div>
    );
}