import React from "react";
import {
    ArrayInput,
    Edit,
    FormDataConsumer,
    required,
    SimpleForm,
    SimpleFormIterator,
    TextField,
    TextInput,
} from "react-admin";

const EditTitle = () => {
    return <span> Edit Menu of : <TextField source="name"/> </span>;
};

export const ScenarioEdit = (props: any) => (
    <Edit title={<EditTitle/>} {...props}>
        <SimpleForm>
            <TextInput source="name" validate={required()} resettable/>
            <ArrayInput source="tags">
                <SimpleFormIterator>
                    <TextInput source="tags" label="Tags"/>
                </SimpleFormIterator>
            </ArrayInput>
            <TextInput multiline fullWidth source="description" label="Description"/>
            <ArrayInput source="scene_description">
                <SimpleFormIterator>
                    <TextInput multiline fullWidth source="scene_description" label="Paragraph"/>
                    <FormDataConsumer>
                        {({getSource, scopedFormData}) => {
                            return (
                                <TextField
                                    record={scopedFormData}
                                    source={getSource === undefined ? "" : getSource('scene_description')}
                                />
                            );
                        }}
                    </FormDataConsumer>
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source="stressors">
                <SimpleFormIterator>
                    <TextInput source="name" label="Name" />
                    <FormDataConsumer>
                        {({ getSource, scopedFormData }) => {
                            return (
                                <TextField
                                    record={scopedFormData}
                                    source ={getSource === undefined? "" : getSource('name')}
                                />
                            );
                        }}
                    </FormDataConsumer>
                    <TextInput source="description" label="Description" />
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);
