import {Box} from "@mui/material";
import * as React from "react";
import {makeStyles} from "@mui/styles";
import Copyright from "./Copyright";
import ManagerVersion from "./ManagerVersion";
import {Theme} from "@mui/material/styles";

const useStyles = makeStyles((theme: Theme) => ({
        footer: {
            position: "fixed",
            bottom: "0",
            textAlign: "center",
            width: "100%",
            color: theme.palette.primary.main
        },
    })
);

function TorcManagerFooter() {
    const classes = useStyles()

    return (
        <footer className={classes.footer}>
            <Box m={3} display="flex" flexDirection={"row"}>
                <Box flexGrow={1} width="33%"><span hidden={true}/></Box>
                <Box flexGrow={1} width="33%">
                    <Copyright/>
                </Box>
                <Box flexGrow={1} width="33%">
                    <ManagerVersion/>
                </Box>
            </Box>
        </footer>
    )
}

export default TorcManagerFooter;