import React from "react";
import {
    List,
    Datagrid,
    TextField,
    DateField,
    ReferenceField,
    Button,
    useRecordContext,
    useRefresh
} from "react-admin";
import LaunchIcon from '@mui/icons-material/Launch';
import {MANAGER_API_ADDRESS, TORC_UI_ADDRESS} from "../../config";
import Typography from '@mui/material/Typography';
import {simpleGet} from "../../auth/authenticatedHttpClient";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import {useNotify} from 'react-admin';
import { GameFilters } from "./GameFilter";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const JoinGameButton = () => {
    const record = useRecordContext();
    const link = TORC_UI_ADDRESS + "/game/" + record.match_id + "?game_token=" + record.owner_token;
    return <Button color="primary" onClick={() => window.open(link, '_blank')} label={"Join"}><LaunchIcon/></Button>;
};

const FecthCardsButton = ({notify}: any) => {
    const record = useRecordContext();
    const link = MANAGER_API_ADDRESS + "/games/" + record.id + "/cards";
    return <Button color="primary" onClick={(event) => {
        event.stopPropagation()
        simpleGet(link).then((response) => {
            if (typeof response !== "undefined") {
                if (response.status === 201) {
                    notify("The cards were successfully fetched!")
                } else {
                    response.text().then((json) => {
                        const apiResponse = JSON.parse(json)
                        notify("Something went wrong: " + apiResponse.message)
                    })
                }
            } else {
                notify("Something went wrong")
            }
        }).catch((error) => {
            notify("Something went wrong: " + error)
        })
    }} label={"Fetch Cards"}><SystemUpdateAltIcon/></Button>;
}

const DuplicateGameButton = ({notify}: any) => {
    const refresh = useRefresh();
    const record = useRecordContext();
    const link = MANAGER_API_ADDRESS + "/games/" + record.id + "/duplicate";
    return <Button color="primary" onClick={(event) => {
        event.stopPropagation();
        simpleGet(link).then((response) => {
            if (typeof response !== "undefined") {
                if (response.status === 201) {
                    refresh()
                    notify("The game was successfully duplicated")
                } else {
                    response.text().then((json) => {
                        const apiResponse = JSON.parse(json)
                        notify("Something went wrong: " + apiResponse.message)
                    })
                }
            } else {
                notify("Something went wrong")
            }
        }).catch((error) => {
            notify("Something went wrong: " + error)
        })
    }} label={"Duplicate"}><ContentCopyIcon/></Button>;
}

const GameMode = ({className}: any) => {
    const record = useRecordContext();
    return <Typography component="span"
                       variant="body2"
                       className={className}>
        {record.options.mode === "RISK_MODE" ? "Risk" : "Resilience"}
    </Typography>;
}

const FeasibilityLevel = ({className}: any) => {
    const record = useRecordContext();
    return <Typography component="span"
                       variant="body2"
                       className={className}>
        {record.options.feasibility_enabled === false? "Feasibility Disabled" : record.options.feasibility_level === 0 ? "Organizational Level" : record.options.feasibility_level === 1 ? "Domain Level" : record.options.feasibility_level === 2 ?"Abstract Level" : "Feasibility Disabled" }
    </Typography>;
}

export const GamesList = (props: any) => {
    const notify = useNotify()

    return (
        <List filters={<GameFilters/>} {...props} title="List of managed games" >
            <Datagrid rowClick={"show"}>
                <TextField label="Name" source="name" emptyText={"None"}/>
                <TextField label="Game ID" source="match_id" sortable={false}/>
                <ReferenceField label="Scenario" source="scenario_id" reference="scenarios" link="show" emptyText={"None"}>
                    <TextField source="name"/>
                </ReferenceField>
                <ReferenceField label="Deck" source="deck_id" reference="decks" link="show" emptyText={"None"}>
                    <TextField source="name"/>
                </ReferenceField>
                <GameMode label="Game mode"/>
                <FeasibilityLevel label="Feasibility Level" source="options.feasibility_level"/>
                <DateField label="Deleted after" source="options.deleted_after" sortable={false}/>
                <DateField label="Last Updated" source="updated_at"/>
                <JoinGameButton/>
                <DuplicateGameButton notify={notify}/>
                <FecthCardsButton notify={notify}/>
            </Datagrid>
        </List>
    )
}
