import {UserIdentity} from 'react-admin';
import fetchJson, {simpleGet} from "./authenticatedHttpClient";
import {MANAGER_AUTH_ADDRESS} from "../config";
import {HttpError} from "ra-core";

const LOGOUT_ENDPOINT = MANAGER_AUTH_ADDRESS + "/auth/logout";
const USER_INFO_ENDPOINT = MANAGER_AUTH_ADDRESS + "/auth/user_info";

const authProvider = {
    login: (params: any) => Promise.resolve(),
    checkError: (error: any) => Promise.resolve(),
    checkAuth: (params: any) => {
        return fetchJson(USER_INFO_ENDPOINT).then(
            () => {
                return Promise.resolve();
            }
        ).catch((e: HttpError) => {
            return Promise.reject()
        })
    },
    logout: () => {
        return fetchJson(USER_INFO_ENDPOINT).then(
            () => {
                return simpleGet(LOGOUT_ENDPOINT).then(
                    () => {
                        window.location.replace("/")
                    }
                )
            }
        ).catch(() => Promise.resolve())
    },
    getIdentity: () => {
        return fetchJson(USER_INFO_ENDPOINT).then(
            (response) => {
                const userId = JSON.parse(response.body)
                return {
                    id: userId.email,
                    fullName: userId.first_name + " " + userId.last_name
                } as UserIdentity
            }
        ).catch(() => {
            return {id: ""} as UserIdentity
        })
    },
    getPermissions: () => Promise.resolve(),
};

export default authProvider;