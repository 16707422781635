import React, {useState} from "react";
import LinearProgress from '@mui/material/LinearProgress';
import {Box, Typography, Button} from '@mui/material';
import {makeStyles} from "@mui/styles";
import {authenticatedUpload} from "../auth/authenticatedHttpClient";

const useStyle = makeStyles({
    error: {
        color: "red"
    }
});

function LinearProgressWithLabel(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

export const UploadFiles = () => {
    const classes = useStyle()

    const [selectedFiles, setSelectedFiles] = useState([])
    const [currentFile, setCurrentFile] = useState(undefined)
    const [progress, setProgress] = useState(0)
    const [currentMessage, setCurrentMessage] = useState("")
    const [isError, setIsError] = useState(false)

    const selectFile = (event) => {
        setCurrentFile(undefined)
        setProgress(0)
        setCurrentMessage("")
        setIsError(false)
        setSelectedFiles(event.target.files)
    }

    const upload = () => {
        let lCurrentFile = ""
        if (selectedFiles.length > 0) {
            lCurrentFile = selectedFiles[0]
        } else {
            return
        }

        setProgress(0)
        setCurrentFile(lCurrentFile)

        authenticatedUpload("action-cards", lCurrentFile, (event) => {
            setProgress(Math.round((100 * event.loaded) / event.total))
        }).then((response) => {
            setCurrentMessage("Actions cards successfully loaded!")
            setIsError(false)
        }).catch((error) => {
            setProgress(0)
            setCurrentMessage("Could not upload the file. Reason: " + error)
            setCurrentFile("")
            setIsError(true)
        })

        setSelectedFiles([])
    }

    return (
        <div className="mg20">
            {currentFile && (
                <Box className="mb25" display="flex" alignItems="center">
                    <Box width="100%" mr={1}>
                        <LinearProgressWithLabel value={progress}/>
                    </Box>
                </Box>)
            }

            <Box display={"flex"} flexDirection={"row"} alignItems="center" style={{marginBottom: "1em"}}>
                <Box>
                    <label htmlFor="btn-upload">
                        <input
                            id="btn-upload"
                            name="btn-upload"
                            style={{display: 'none'}}
                            type="file"
                            onChange={selectFile}/>
                        <Button
                            className="btn-choose"
                            variant="outlined"
                            component="span">
                            Choose File
                        </Button>
                    </label>
                </Box>
                <Box flexGrow={1}/>
                <Box>
                    <Button
                        className="btn-upload"
                        color="primary"
                        variant="contained"
                        component="span"
                        disabled={selectedFiles.length === 0}
                        onClick={upload}>
                        Upload
                    </Button>
                </Box>
            </Box>

            <div className="file-name">
                {selectedFiles.length > 0 ? "File: " + selectedFiles[0].name : null}
            </div>

            <Typography variant="subtitle2" className={isError ? classes.error : null}>
                {currentMessage}
            </Typography>
        </div>
    );
}